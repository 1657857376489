import { lazy } from "react";
const Templates = lazy(() => import("../../components/root/Templates"));
const WebApps = lazy(() => import("../../features/prompt-studio"));
const Builder = lazy(() => import("../../components/root/Builder"));
const PageNotFound = lazy(() => import("../../pages/PageNotFound"));
const LoginPage = lazy(() => import("../../pages/LoginPage"));
const HomePage = lazy(() => import("../../features/onboarding/home"));
const Workspaces = lazy(() => import("../../features/onboarding/workspaces"));
const LandingPage = lazy(() => import("../../pages/landing"));
const TestSuite = lazy(() => import("../../features/test-suite"));
const Documents = lazy(() => import("../../features/documents"));
const TestSuiteDynamic = lazy(() =>
  import("../../features/test-suite/dynamic-test-suite")
);
const DocumentsDynamic = lazy(() =>
  import("../../features/documents/documents-dynamic")
);
const Settings = lazy(() => import("../../features/settings/"));
const General = lazy(() => import("../../features/settings/general"));
const OrganisationManagement = lazy(() =>
  import("../../features/settings/organisation-management")
);
const AllPlaygrounds = lazy(() =>
  import("../../features/prompt-studio/playground")
);
const PlaygroundDynamic = lazy(() =>
  import("../../features/prompt-studio/playground/dynamic-playground")
);
const ApiKeys = lazy(() => import("../../features/settings/api-keys"));
const Provider = lazy(() => import("../../features/settings/providers"));
// const HomePage= lazy(() => import("../../pages/WelcomePage.jsx"));
// import ErrorPage from "../../pages/ErrorPage";
const ErrorPage = lazy(() => import("../../pages/ErrorPage"));
const Deployments = lazy(() => import("../../features/deployments"));
const Workflows = lazy(() => import("../../features/workflows"));
const UseCaseDynamic = lazy(() =>
  import("../../pages/use-cases/dynamic-use-case")
);
const Documentation = lazy(() => import("../../pages/docs"));

export const ALL_LINKS = {
  HomePage: {
    pageLink: "/home",
    view: HomePage,
    loginRequire: true,
    show: {
      null: true,
      admin: true,
      user: true,
      playground_tester: true,
      suite_tester: true,
    },
  },

  ErrorPage: {
    pageLink: "/error",
    view: ErrorPage,
    loginRequire: true,
    show: {
      null: true,
      admin: true,
      user: true,
      playground_tester: true,
      suite_tester: true,
    },
  },
  Workspaces: {
    pageLink: "/workspaces",
    view: Workspaces,
    loginRequire: true,
    show: {
      null: true,
      admin: true,
      user: true,
      playground_tester: false,
      suite_tester: false,
    },
  },
  LoginPage: {
    pageLink: "/login",
    view: LoginPage,
    loginRequire: false,
    show: {
      null: true,
      admin: true,
      user: true,
      playground_tester: false,
      suite_tester: false,
    },
  },
  TestSuiteDynamic: {
    pageLink: "/test-suite/:id",
    view: TestSuiteDynamic,
    loginRequire: true,
    show: {
      null: true,
      admin: true,
      user: true,
      playground_tester: false,
      suite_tester: true,
    },
  },
  DocumentsDynamic: {
    pageLink: "/datastore/:id",
    view: DocumentsDynamic,
    loginRequire: true,
    show: {
      null: true,
      admin: true,
      user: true,
      playground_tester: false,
      suite_tester: false,
    },
  },
  TestSuite: {
    pageLink: "/test-suite",
    view: TestSuite,
    loginRequire: true,
    show: {
      null: true,
      admin: true,
      user: true,
      playground_tester: false,
      suite_tester: true,
    },
  },
  Documents: {
    pageLink: "/datastore",
    view: Documents,
    loginRequire: true,
    show: {
      null: true,
      admin: true,
      user: true,
      playground_tester: false,
      suite_tester: false,
    },
  },
  TemplatesDefault: {
    pageLink: "/templates",
    view: Templates,
    loginRequire: true,
    show: {
      null: true,
      admin: true,
      user: true,
      playground_tester: false,
      suite_tester: false,
    },
  },
  Templates: {
    pageLink: "/templates/:id",
    view: Templates,
    loginRequire: true,
    show: {
      null: true,
      admin: true,
      user: true,
      playground_tester: false,
      suite_tester: false,
    },
  },
  Builder: {
    pageLink: "/prompt-studio",
    view: Builder,
    loginRequire: true,
    show: {
      null: true,
      admin: true,
      user: true,
      playground_tester: true,
      suite_tester: false,
    },
  },
  WebApps: {
    pageLink: "/prompt-studio/webapps",
    view: WebApps,
    loginRequire: true,
    show: {
      null: true,
      admin: true,
      user: true,
      playground_tester: true,
      suite_tester: false,
    },
  },
  Settings: {
    pageLink: "/settings",
    view: Settings,
    loginRequire: true,
    show: {
      null: true,
      admin: true,
      user: true,
      playground_tester: true,
      suite_tester: true,
    },
  },
  AllPlaygrounds: {
    pageLink: "/prompt-studio/playground",
    view: AllPlaygrounds,
    loginRequire: true,
    show: {
      null: true,
      admin: true,
      user: true,
      playground_tester: true,
      suite_tester: false,
    },
  },
  PlaygroundDynamic: {
    pageLink: "/prompt-studio/playground/:id",
    view: PlaygroundDynamic,
    loginRequire: true,
    show: {
      null: true,
      admin: true,
      user: true,
      playground_tester: true,
      suite_tester: false,
    },
  },
  ApiKeys: {
    pageLink: "/settings/api-keys",
    view: ApiKeys,
    loginRequire: true,
    show: {
      null: true,
      admin: true,
      user: true,
      playground_tester: true,
      suite_tester: true,
    },
  },
  Provider: {
    pageLink: "/settings/providers",
    view: Provider,
    loginRequire: true,
    show: {
      null: true,
      admin: true,
      user: true,
      playground_tester: true,
      suite_tester: true,
    },
  },
  General: {
    pageLink: "/settings/general",
    view: General,
    loginRequire: true,
    show: {
      null: true,
      admin: true,
      user: true,
      playground_tester: true,
      suite_tester: true,
    },
  },
  OrganisationManagement: {
    pageLink: "/settings/organisation-management",
    view: OrganisationManagement,
    loginRequire: true,
    show: {
      null: true,
      admin: true,
      user: true,
      playground_tester: true,
      suite_tester: true,
    },
  },
  Deployments: {
    pageLink: "/deployments",
    view: Deployments,
    loginRequire: true,
  },
  Workflows: {
    pageLink: "/workflows",
    view: Workflows,
    loginRequire: true,
  },
};

export const PUBLIC_LINKS = {
  PageNotFound: {
    pageLink: "*",
    view: PageNotFound,
    loginRequire: false,
    show: {
      null: true,
      admin: true,
      user: true,
      playground_tester: true,
      suite_tester: true,
    },
  },
  DefaultPage: {
    pageLink: "/",
    view: LandingPage,
    loginRequire: false,
    show: {
      null: true,
      admin: true,
      user: true,
      playground_tester: true,
      suite_tester: true,
    },
  },
  AccessPage: {
    pageLink: "/access",
    view: LandingPage,
    loginRequire: false,
    show: {
      null: true,
      admin: true,
      user: true,
      playground_tester: true,
      suite_tester: true,
    },
  },
  UseCaseDynamic: {
    pageLink: "/use-cases/:id",
    view: UseCaseDynamic,
    loginRequire: false,
    show: {
      null: true,
      admin: true,
      user: true,
      playground_tester: true,
      suite_tester: true,
    },
  },
  Documentation: {
    pageLink: "/docs",
    view: Documentation,
    loginRequire: false,
    show: {
      null: true,
      admin: true,
      user: true,
      playground_tester: true,
      suite_tester: true,
    },
  },
};

export const PRIVATE_PAGES = {
  settings: [
    ALL_LINKS.Settings,
    ALL_LINKS.ApiKeys,
    ALL_LINKS.Provider,
    ALL_LINKS.General,
    ALL_LINKS.OrganisationManagement,
  ],
  studio: [ALL_LINKS.AllPlaygrounds, ALL_LINKS.PlaygroundDynamic],
  testsuite: [ALL_LINKS.TestSuite, ALL_LINKS.TestSuiteDynamic],
  datastore: [ALL_LINKS.Documents, ALL_LINKS.DocumentsDynamic],
  others: [
    ALL_LINKS.HomePage,
    ALL_LINKS.Workspaces,
    ALL_LINKS.Templates,
    ALL_LINKS.TemplatesDefault,
    ALL_LINKS.WebApps,
    ALL_LINKS.Deployments,
    ALL_LINKS.Workflows,
  ],
  important: [
    // ALL_LINKS.UnAuthorisedPage
  ],
  onboarding: [],
  deployments: [],
};

export const PUBLIC_PAGES = [
  PUBLIC_LINKS.DefaultPage,
  PUBLIC_LINKS.PageNotFound,
  ALL_LINKS.ErrorPage,
  PUBLIC_LINKS.UseCaseDynamic,
  PUBLIC_LINKS.Documentation,
  PUBLIC_LINKS.AccessPage,
];
