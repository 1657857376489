import { configureStore } from '@reduxjs/toolkit';
import userSlice from './userSlice';
import onboardingSlice from './onboardingSlice';
const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    onboarding:onboardingSlice.reducer
   },
});

export default store;