import bg_01 from "./backgrounds/bg_01.jpeg";

import error from "./illustrations/404.svg";
import alert_signal from "./illustrations/alert_signal.svg";
import appreciation from "./illustrations/appreciation.svg";
import art_lover from "./illustrations/art_lover.svg";
import bee from "./illustrations/bee.svg";
import blow_dry_hair from "./illustrations/blow_dry_hair.svg";
import books from "./illustrations/books.svg";
import calendar from "./illustrations/calendar.svg";
import credit_card_payments from "./illustrations/credit_card_payments.svg";
import currect_location from "./illustrations/currect_location.svg";
import design_tools from "./illustrations/design_tools.svg";
import first_place_medal from "./illustrations/first_place_medal.svg";
import flying_turtle from "./illustrations/flying_turtle.svg";
import group_chat from "./illustrations/group_chat.svg";
import hacker from "./illustrations/hacker.svg";
import half_moon from "./illustrations/half_moon.svg";
import ice_cream from "./illustrations/ice_cream.svg";
import internet_cats from "./illustrations/internet_cats.svg";
import internet from "./illustrations/internet.svg";
import lightbulb from "./illustrations/lightbulb.svg";
import mailbox from "./illustrations/mailbox.svg";
import makeup_retouch from "./illustrations/makeup_retouch.svg";
import microchip from "./illustrations/microchip.svg";
import mobile_connectivity from "./illustrations/mobile_connectivity.svg";
import moving_boxes from "./illustrations/moving_boxes.svg";
import music_maker from "./illustrations/music_maker.svg";
import new_ideas from "./illustrations/new_ideas.svg";
import newsletter from "./illustrations/newsletter.svg";
import notifications from "./illustrations/notifications.svg";
import online_dating from "./illustrations/online_dating.svg";
import online_shopping from "./illustrations/online_shopping.svg";
import online_studying from "./illustrations/online_studying.svg";
import paying_taxes from "./illustrations/paying_taxes.svg";
import photo_session from "./illustrations/photo_session.svg";
import qe from "./illustrations/qe.svg";
import recycle_bin from "./illustrations/recycle_bin.svg";
import recycle from "./illustrations/recycle.svg";
import reminders from "./illustrations/reminders.svg";
import science from "./illustrations/science.svg";
import search_engine from "./illustrations/search_engine.svg";
import skater from "./illustrations/skater.svg";
import space_rocket from "./illustrations/space_rocket.svg";
import space from "./illustrations/space.svg";
import task_manager from "./illustrations/task_manager.svg";
import tetris_game from "./illustrations/tetris_game.svg";
import texting from "./illustrations/texting.svg";
import to_do_list from "./illustrations/to_do_list.svg";
import van from "./illustrations/van.svg";
import vases from "./illustrations/vases.svg";
import wind_in_hair from "./illustrations/wind_in_hair.svg";

import hero from "./backgrounds/hero.png";
import bg_cover_1 from "./covers/bg_cover_1.png";
import bg_cover_2 from "./covers/bg_cover_2.png";


import ankit from "./people/ankit.png";
import hunny from "./people/hunny.jpg";
import lalit from "./people/lalit.jpg";


import hero_16 from "./backgrounds/hero_16.png";
import hero_17 from "./backgrounds/hero_17.png";

import creative from "./who-should-use/creative.svg";
import engineering from "./who-should-use/engineering.svg";
import marketing from "./who-should-use/marketing.svg";
import musicians from "./who-should-use/musicians.svg";
import students from "./who-should-use/students.svg";
import teachers from "./who-should-use/teachers.svg";

import logo from "./logo.png";
export const assets = {
  bg_01,
  illustrations: {
    alert_signal,
    appreciation,
    art_lover,
    bee,
    blow_dry_hair,
    books,
    calendar,
    credit_card_payments,
    currect_location,
    design_tools,
    error,
    first_place_medal,
    flying_turtle,
    group_chat,
    hacker,
    half_moon,
    ice_cream,
    internet_cats,
    internet,
    lightbulb,
    mailbox,
    makeup_retouch,
    microchip,
    mobile_connectivity,
    moving_boxes,
    music_maker,
    new_ideas,
    newsletter,
    notifications,
    online_dating,
    online_shopping,
    online_studying,
    paying_taxes,
    photo_session,
    qe,
    recycle_bin,
    recycle,
    reminders,
    science,
    search_engine,
    skater,
    space_rocket,
    space,
    task_manager,
    tetris_game,
    texting,
    to_do_list,
    van,
    vases,
    wind_in_hair,
  },
  people: {
    lalit,
    hunny,
    ankit
  },
  covers: {
    bg_cover_1,
    bg_cover_2,
  },
  hero,
  logo,
  heros: {
    hero_16,
    hero_17,
  },
  whoShouldUse: {
    creative,
    engineering,
    marketing,
    musicians,
    students,
    teachers,
  },
};
