import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import "./index.css";
import App from "./App";
import store from "./redux/index";
import { AuthContextProvider } from "./context/AuthContext";
import { Auth0Provider } from "@auth0/auth0-react";
import { AuthInject } from "./utils/AutoInject";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
    domain="devneural.us.auth0.com"
    clientId="1Vr1n9XwqoegeEok7vAeqxAxLhR6SQ05"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: "https://promptchannel.com",
    }}
  >
    <Provider store={store}>
      <AuthContextProvider>
        <AuthInject />
        <App />
      </AuthContextProvider>
    </Provider>
  </Auth0Provider>
);
