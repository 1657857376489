import { useAuth0 } from "@auth0/auth0-react";
import {  useLayoutEffect } from "react";
import { authInjector } from "../services/http_service";
import { useSelector } from "react-redux";

export function AuthInject() {
    const { getAccessTokenSilently,getIdTokenClaims } = useAuth0();
    const refresh=useSelector(state=>state.user.refresh);
    
    const saveIdToken=async()=>{
        authInjector.setAuthGetter(getAccessTokenSilently,getIdTokenClaims);
    }
  
    useLayoutEffect(() => {
      saveIdToken();
      return () => authInjector.setAuthGetter(undefined,undefined);
    }, [getAccessTokenSilently,refresh]);
  
    return null;
  }
  