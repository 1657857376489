import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApps } from "../features/onboarding/services/onBoardingServices";

const initialState={
    categories:null,
    loading:true
}

const onboardingSlice=createSlice({
    name:"onboarding",
    initialState,
    reducers:{
        setOnboarding:(state,action)=>{
            let categories=[];
            Object.keys(action.payload).forEach((key)=>{
                categories.push({
                    ...action.payload[key],
                    category_id:action.payload[key].category_id,
                    category_name:action.payload[key].category_name,
                    category_url:action.payload[key].category_url,
                    description:action.payload[key].description,
                    parent_category_id:action.payload[key].parent_category_id ? action.payload[key].parent_category_id : null,
                });

                action.payload[key].children.forEach((child)=>{
                    categories.push({
                        ...child,
                        category_id:child.category_id,
                        category_name:child.category_name,
                        category_url:child.category_url,
                        description:child.description,
                        parent_category_id:child.parent_category_id ? child.parent_category_id : null,
                    });
                })
            })

            state.categories=categories;
            state.loading=false;
        
        }
    }
});


  export const getAppsThunk=createAsyncThunk(
        '/v1/category/hierarchy',
        async (_, {dispatch})=>{
            try{
                const {data}=await getApps();
                dispatch(onboardingSlice.actions.setOnboarding(data));
                // console.log(data)
                return data;
            }
            catch(error){
                throw error;
            }
        }
    )

export const onboardingActions=onboardingSlice.actions;
export default onboardingSlice;

