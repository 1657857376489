import {
  AcUnit,
  Apartment,
  Article,
  BugReport,
  Diversity2,
  Games,
  GridView,
  Home,
  Key,
  LibraryAddCheck,
  Person,
  RocketLaunch,
  Workspaces,
} from "@mui/icons-material";
import { ALL_LINKS } from "../navigation-routes";

export const MENU_BUTTONS = {
  HOME: {
    icon: <Home />,
    pageLink: ALL_LINKS.HomePage.pageLink,
    title: "HOME",
  },
  WORKSPACES: {
    icon: <Workspaces />,
    pageLink: ALL_LINKS.Workspaces.pageLink,
    title: "USE CASES",
  },
  TEMPLATES: {
    icon: <GridView />,
    pageLink: ALL_LINKS.TemplatesDefault.pageLink,
    title: "TEMPLATES",
  },
  BUILDER: {
    icon: <AcUnit />,
    pageLink: ALL_LINKS.Builder.pageLink,
    title: "BUILDER",
  },
  DOCUMENTS: {
    icon: <Article />,
    pageLink: ALL_LINKS.Documents.pageLink,
    title: "DATASTORE",
  },
  PLAYGROUND: {
    icon: <Games />,
    pageLink: ALL_LINKS.AllPlaygrounds.pageLink,
    title: "PROMPT STUDIO",
  },
  GENERAL: {
    icon: <Person />,
    pageLink: ALL_LINKS.General.pageLink,
    title: "GENERAL",
  },
  APIKEYS: {
    icon: <Key />,
    pageLink: ALL_LINKS.ApiKeys.pageLink,
    title: "API KEYS",
  },
  PROVIDERS: {
    icon: <LibraryAddCheck />,
    pageLink: ALL_LINKS.Provider.pageLink,
    title: "PROVIDERS",
  },
  ORGANISATION_MANAGEMENT: {
    icon: <Apartment />,
    pageLink: ALL_LINKS.OrganisationManagement.pageLink,
    title: "ORGANISATION",
  },
  TEST_SUITE: {
    icon: <BugReport />,
    pageLink: ALL_LINKS.TestSuite.pageLink,
    title: "TEST SUITES",
  },
  DEPLOYMENTS: {
    icon: <RocketLaunch />,
    pageLink: ALL_LINKS.Deployments.pageLink,
    title: "DEPLOYMENTS",
  },
  WORKFLOWS: {
    icon: <Diversity2 />,
    pageLink: ALL_LINKS.Workflows.pageLink,
    title: "WORKFLOWS",
  },
};
