
export function getFormData(data) {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    return formData;
}

export function permissionsConverter(input) {
    const cleanedInput = input.slice(1, -1).replace(/"/g, '');

    const parts = cleanedInput.split(',').map(part => part.trim());

    return parts;
}

export function colorConverter(input) {
    const words = input.split('_');
    const titleCaseWords = words.map((word) => {
      const lowercaseWord = word.toLowerCase();
      return lowercaseWord;
    });
    return titleCaseWords;
}

export const stream=new ReadableStream({
  read(){}
});
