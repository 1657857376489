import React from 'react'
import styles from './index.module.css'
const SplashScreen = () => {
  return (
    <div className='h-[100vh] bg-black text-white flex flex-col justify-center items-center'>
      <div className={styles.loader}>
      <p className={' mb-3 '}>Loading</p>
      <div className={styles.loading}>
        <div className={styles.load}></div>
        <div className={styles.load}></div>
        <div className={styles.load}></div>
        <div className={styles.load}></div>
       </div>
       </div>
       </div>
  )
}

export default SplashScreen