import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserInfo } from "../services/userService";
import { colorConverter } from "../utils/commonFunctions";

const initialState = {
    userMetaData: null,
    refresh: false,
    loading:true,
    workspace: {},
    userinfo: {},
    themeMode: localStorage.getItem("themeMode") || "light",
    themeColor: localStorage.getItem("themeColor") || "blue",
    };

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserMetaData: (state, action) => {
            state.userMetaData = action.payload;
        },
        refresh: (state, action) => {
            state.refresh = !state.refresh;
        },
        setWorkspace: (state, action) => {
            state.workspace = action.payload;
        },
        setUserInfo: (state,action)=>{
            state.userinfo=action.payload;
        },
        setThemeMode: (state, action) => {
            state.themeMode = action.payload;
            localStorage.setItem("themeMode", action.payload);
        },
        setThemeColor: (state, action) => {
            state.themeColor = action.payload;
            localStorage.setItem("themeColor", action.payload);
        },
        toggleLoading: (state, action) => {
            state.loading =action.payload;
        }

    },
});


export const getUserInfoThunk = createAsyncThunk(
    'user/getUserInfo',
    async (_, { dispatch }) => {
      try {
        const { data } = await getUserInfo(); // Assuming getUserInfo is an asynchronous API function
        const theme =
          data?.app_metadata?.organisations[data?.active_organisation_id]?.teams[
            data?.active_team_id
          ]?.workspaces[data?.active_workspace_id]?.theme;
        const colors = colorConverter(theme);
  
        dispatch(userActions.setThemeMode(colors[0]));
        dispatch(userActions.setThemeColor(colors[1]));
        dispatch(userActions.setUserInfo(data));
        dispatch(userActions.toggleLoading(false));
        return { data, colors };
      } catch (error) {
        // Handle errors here, e.g., dispatch an error action
        throw error;
      }
      finally{
       
      }
    }
  );

export const userActions= userSlice.actions;


export default userSlice;
